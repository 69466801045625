



































































.colorSwatches {
  height: 55px;
  width: 55px;
  border-radius: 512px;

  &-mini {
    height: 24px;
    width: 24px;
    margin: 4px;
    border-radius: 512px;
  }
}
